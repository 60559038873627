

























import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { REQUEST_DB_TABLES_CSV } from '@/store/evaluation/actions'
import { SimpleServerResponse } from '@/store/types'
@Component
export default class Evaluation extends Vue {
  @Action(REQUEST_DB_TABLES_CSV)
  requestDownload: () => Promise<SimpleServerResponse>

  requestExport (): void {
    this.$root.$emit('load')
    this.requestDownload().then(() => {
      this.$root.$emit('alert', this.$t('info.success'), this.$t('info.email-send-successful').toString(), false)
    }).catch(() => {
      this.$root.$emit('alert', this.$t('warning.error'), this.$t('info.email-send-failed').toString(), true)
    }).finally(() => {
      this.$root.$emit('end-load')
    })
  }
}
